import axios from '../index'

export default {
  submit(data:any){
    return axios({
      url:'/commodityMessage/submit',
      method:'POST',
      data
    })
  },
  list(data:any){
    return axios({
      url:'/commodityMessage/list',
      method:'GET',
      data
    })
  },
  publicList(data:any){
    return axios({
      url:'/commodityMessage/public/list',
      method:'GET',
      data
    },{
      aloneBaseUrl:'/api-msg'
    })
  },
  detail(id:any){
    return axios({
      url:'/commodityMessage/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  publicDetail(id:any){
    return axios({
      url:'/commodityMessage/public/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  isCollect(messageId:any){
    return axios({
      url:'/commodityMessageCollection/isCollection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  collect(messageId:any){
    return axios({
      url:'/commodityMessageCollection/collection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  remove(id:any){
    return axios({
      url:'/commodityMessage/remove?id='+id,
      method:'POST',
    })
  },
  pageView(id:any){
    return axios({
      url:'/commodityMessage/pageView?id='+id,
      method:'GET',
    })
  },
  submitConfirm(id:any){
    return axios({
      url:'/commodityMessage/submitConfirm?id='+id,
      method:'GET',
    })
  },
  toConfirm(id:any){
    return axios({
      url:'/commodityMessage/submitConfirm?id='+id,
      method:'GET',
    })
  },
  banner(messageId:any){
    return axios({
      url:'/commodityMessageBanner/listAll?messageId='+messageId,
      method:'GET',
    })
  },
  publicBanner(messageId:any){
    return axios({
      url:'/commodityMessageBanner/public/listAll?messageId='+messageId,
      method:'GET',
    })
  },
  //审核  id status:1审批通过 2审批不通过
  check(data:any){
    return axios({
      url:'/commodityMessage/check',
      method:'GET',
      data
    })
  },
}